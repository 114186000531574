import { mapHelper } from 'common/utils'

// 协议层级
const belongType = [
  {
    text: '平台',
    value: 9
  },
  {
    text: '公司',
    value: 1
  },
  {
    text: 'APP',
    value: 2
  },
  {
    text: '小程序',
    value: 3
  }
]

const {
  map: belongTypeMap,
  setOps: setBelongTypeOps
} = mapHelper.setMap(belongType)

// 登录端类型
const appType = [
  {
    text: '用户端',
    value: 1
  },
  {
    text: '服务端',
    value: 2
  },
  {
    text: '商家端',
    value: 3
  }
]

const {
  map: appTypeMap,
  setOps: setAppTypeOps
} = mapHelper.setMap(appType)

export {
  belongTypeMap,
  setBelongTypeOps,
  appTypeMap,
  setAppTypeOps
}
