<template>
  <div class="ProtocolMgrList-wrapper">
    <list
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      ref="list"
    >
      <template #headerSlot>
        <v-button v-if="visible" text="新增" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-input label="协议名称" v-model="searchParams.agreementName" />
        <v-select label="登录端类型" v-model="searchParams.appType" :options="appTypeOps" />
        <v-select2 label="协议类型" v-model="searchParams.agreementType" v-bind="agreementTypeParams" :subjoin="agreementTypeSubjoin" />
        <v-select label="协议层级" v-model="searchParams.belongType" :options="belongTypeOps" @change="belongTypeChange" />
        <v-select2 ref="select2BelongOrg" v-if="searchParams.belongType === 1" label="协议归属" v-model="searchParams.belongOrg" v-bind="regionParams" />
        <v-select2 label="协议归属" v-if="searchParams.belongType === 2" ref="select2BelongOrg2" v-model="searchParams.belongOrg" placeholder="查询归属" v-bind="appParams" :subjoin="{ appType: searchParams.appType }" />
        <v-select label="协议归属" v-if="searchParams.belongType === 3" v-model="searchParams.belongOrg" :options="protocolMPOps" />
        <v-select2 label="操作人" v-model="searchParams.updateUser" v-bind="updateUserParams" />
        <v-datepicker label="最近操作时间" :maxDate="maxDate" type="rangedatetimer" :startTime.sync="searchParams.updateTimeFrom" :endTime.sync="searchParams.updateTimeTo" />
      </template>
      <template #operateSlot="scope">
        <v-button v-if="visible && editVisible(scope.row)" text="编辑" type="text" permission="update" @click="edit(scope.row)" />
        <v-button v-if="visible && editVisible(scope.row)" text="查看" type="text" permission="update" @click="check(scope.row)" />
        <v-button v-if="visible && editVisible(scope.row)" text="删除" type="text" permission="delete" @click="deleteItem(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { regionParams } from 'common/select2Params'
import { getListURL, exportListURL, agreementTypeUrl, getUserListURL, searchAPPURL, getMPBelongURL, deleteByAgreementId } from './api'
import { appTypeMap, setAppTypeOps, setBelongTypeOps } from './map'

export default {
  name: 'ProtocolMgrList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      appTypeOps: setAppTypeOps(1),
      belongTypeOps: setBelongTypeOps(1),
      maxDate: new Date(),
      agreementTypeParams: {
        searchUrl: agreementTypeUrl,
        request: {
          text: 'value',
          value: 'code'
        },
        response: {
          text: 'value',
          value: 'code'
        }
      },
      regionParams,
      updateUserParams: {
        searchUrl: getUserListURL,
        request: {
          text: 'keyword',
          value: 'id'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      appParams: {
        searchUrl: searchAPPURL,
        request: {
          text: 'appName',
          value: 'id'
        },
        response: {
          text: 'appName'
        }
      },
      searchParams: {
        agreementName: '',
        appType: undefined,
        agreementType: undefined,
        belongType: undefined,
        belongOrg: undefined,
        updateUser: '',
        updateTimeFrom: '',
        updateTimeTo: ''
      },
      headers: [
        {
          prop: 'agreementName',
          label: '协议名称'
        },
        {
          prop: 'versionCode',
          label: '协议版本'
        },
        {
          prop: 'appTypeText',
          label: '登录端类型',
          formatter: row => appTypeMap[row.appType]
        },
        {
          prop: 'agreementType',
          label: '协议类型'
        },
        {
          prop: 'belongTypeName',
          label: '协议层级'
        },
        {
          prop: 'belongOrg',
          label: '协议归属'
        },
        {
          prop: 'updateUser',
          label: '操作人'
        },
        {
          prop: 'updateTime',
          label: '最后操作时间'
        }
      ],
      protocolMPOps: [],
      belongTypeEcho: false
    }
  },
  computed: {
    visible () {
      const userType = this.$store.state.userInfo.userType
      return ['106', '100', '102'].includes(userType)
    },
    agreementTypeSubjoin () {
      return {
        typeValue: 'APPAGREEMENT',
        codeLevel: 2,
        superCode: this.searchParams.appType
      }
    },
    belongOrgVisible () {
      return this.searchParams.belongType && this.searchParams.belongType !== 9
    }
  },

  created () {
    this.getMicroAppInfoData()
  },
  methods: {
    belongTypeChange (value) {
      this.searchParams.belongOrg = undefined
    },
    create () {
      this.$router.push({
        name: 'protocolMgrForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'protocolMgrForm',
        query: {
          id: row.id,
          type: 'edit'
        }
      })
    },
    check (row) {
      this.$router.push({
        name: 'protocolMgrForm',
        query: {
          id: row.id,
          type: 'check'
        }
      })
    },
    async deleteItem(row) {
      let isOk = await this.$confirm('确认删除？')
      if (isOk) {
        let postData = {
          agreementId: row.id,
        }
        this.$axios({
          method: 'get',
          url: deleteByAgreementId,
          params: postData,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          }
        }).then(res => {
          if (res.status === 100) {
            this.$refs.list.searchData()
            this.$message.success('操作成功')
          }
        })
      }
    },
    editVisible (row) {
      const userType = this.$store.state.userInfo.userType
      const belongType = row.belongType
      // 非平台/集团账号不可操作平台协议
      if (belongType === 9 && !['106', '100'].includes(userType)) {
        return false
      }
      return true
    },

    // 获取小程序数据
    async getMicroAppInfoData () {
      // getMPBelongURL
      const { data, status } = await this.$axios.get(getMPBelongURL)
      if (status === 100) {
        let list = data || []
        list.forEach(item => {
          item.text = item.name
          item.value = item.appId
        })
        list.unshift({
          text: '全部',
          value: undefined
        })
        this.protocolMPOps = list
      }
    },
  }
}
</script>
