// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}sysAppAgreement/sysAppAgreementInfoList`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}sysAppAgreement/list/export`
// 获取协议类型列表
const agreementTypeUrl = `${API_CONFIG.butlerBaseURL}sysCodeword/getSysCodeWordByCodeLevel`
// 详情
const getDetailUrl = `${API_CONFIG.butlerBaseURL}sysAppAgreement/getDetail`
// 新增
const createURL = `${API_CONFIG.butlerBaseURL}sysAppAgreement/add`
// 编辑
const updateURL = `${API_CONFIG.butlerBaseURL}sysAppAgreement/update`
// 操作人 select2
const getUserListURL = `${API_CONFIG.butlerBaseURL}agreement/user/search/keyword`
// 协议归属 select2
const getOrganizeURL = `${API_CONFIG.baseURL}serverRoleAction!getOrganize.action`
//
const searchAPPURL = `${API_CONFIG.butlerBaseURL}appInfo/selectAppinfo`
// 协议归属 小程序 select2
const getMPBelongURL = `${API_CONFIG.butlerBaseURL}community/microapp/list/microAppInfo`

// 获取APP级的版本号
export const getVersionOps = `${API_CONFIG.butlerBaseURL}sysAppAgreement/app/appAgreementVersionCode`

// 获取协议内容
export const getAppAgreementVersionInfo = `${API_CONFIG.butlerBaseURL}sysAppAgreement/appAgreementVersionInfo`

// 删除协议内容
export const deleteByAgreementId = `${API_CONFIG.butlerBaseURL}sysAppAgreement/app/deleteByAgreementId`

export {
  getListURL,
  exportListURL,
  agreementTypeUrl,
  getDetailUrl,
  createURL,
  updateURL,
  getUserListURL,
  getOrganizeURL,
  searchAPPURL,
  getMPBelongURL
}
